import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { ButtonComponent } from '@zolteam/axenergie-ui-library';
// Constants
import strings from '../../constants/Strings';
import useIsMobile from '../../hooks/useIsMobile';

const AgencyContactBlock = ({
  agency, beCalledBack, setIsPaymentModalOpen, isFreeInvoicePaymentEnabled,
}) => {
  const [isPhoneVisible, setIsPhoneVisible] = useState(false);
  const [isMobile] = useIsMobile();

  const sendDatalayerEvent = (eventName) => {
    if (!window) {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
      agency: agency.name,
    });
  };

  return (
    <div
      className="white-background d-flex f-row align-center justify-between ph-3"
      style={{ width: '80%', minHeight: 120, flexWrap: isMobile ? 'wrap' : 'nowrap' }}
    >
      <div className={`d-flex f-row align-center justify-center ${isMobile ? 'pt-3 f-wrap' : ''}`}>
        <Image
          src={`https://maps.googleapis.com/maps/api/staticmap?size=240x200&zoom=11&markers=
              ${agency.latitude},${agency.longitude}&key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}`}
          width={120}
          height={100}
          alt="agency-map-location"
        />
        <div className="d-flex f-column grey-600-text normal-weight ml-4 mv-2">
          <span className="bold grey-800-text">{agency.name}</span>
          <span>{agency.address1}</span>
          <span>{agency.address2}</span>
          <span>
            <span className="mr-2">{agency.postalCode}</span>
            <span>{agency.city}</span>
          </span>
        </div>
      </div>
      <div className="d-flex f-row f-wrap align-center justify-center">
        <div className="d-flex f-row f-wrap align-center justify-center mv-2">
          <div className="mh-3 mv-2">
            {agency?.phones?.length
              ? (
                <ButtonComponent
                  onClick={() => {
                    sendDatalayerEvent('agencyNumber');
                    setIsPhoneVisible(true);
                  }}
                  theme="outline"
                >
                  <div className="mh-4 mv-2">
                    {
                        isPhoneVisible
                          ? <span>{agency.phones?.[0]?.phone}</span>
                          : <span>{strings.seeNumber}</span>
                    }
                  </div>
                </ButtonComponent>
              ) : null}
          </div>
          <div className="mh-3 mv-2">
            {agency?.externalLink
              ? (
                <a target="_blank" href={agency?.externalLink?.url} rel="noreferrer">
                  <ButtonComponent onClick={() => {}} theme="grey">
                    <div className="mh-4 mv-2">
                      {agency.externalLink?.label}
                    </div>
                  </ButtonComponent>
                </a>
              ) : null}
          </div>
          <div className="mh-3 mv-2">
            <ButtonComponent onClick={() => {
              sendDatalayerEvent('callBack');
              beCalledBack();
            }}
            >
              <div className="mh-4 mv-2">
                <span className="uppercase">
                  {strings.beCalled}

                </span>
              </div>
            </ButtonComponent>
          </div>
          {isFreeInvoicePaymentEnabled
            ? (
              <div className="mh-3 mv-2 outline-grey">
                <ButtonComponent
                  type="button"
                  onClick={() => {
                    sendDatalayerEvent('payOnline');
                    setIsPaymentModalOpen(true);
                  }}
                  theme="black"
                >
                  <div className="mv-2">
                    {strings.payOnline}
                  </div>
                </ButtonComponent>
              </div>
            )
            : null}
        </div>
      </div>
    </div>
  );
};

AgencyContactBlock.propTypes = {
  agency: PropTypes.shape({
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    name: PropTypes.string,
    address1: PropTypes.string,
    postalCode: PropTypes.string,
    openingHours: PropTypes.string,
    city: PropTypes.string,
    phones: PropTypes.arrayOf(
      PropTypes.shape({ phone: PropTypes.string, label: PropTypes.string }),
    ),
  }).isRequired,
  beCalledBack: PropTypes.func.isRequired,
  setIsPaymentModalOpen: PropTypes.func.isRequired,
  isFreeInvoicePaymentEnabled: PropTypes.bool.isRequired,
};

export default AgencyContactBlock;
