import colors from '../constants/Colors';

const getColorCode = (colorId) => {
  switch (colorId) {
    case (1): {
      return colors.prestationBlue;
    }
    case (2): {
      return colors.prestationGreen;
    }
    case (3): {
      return colors.prestationPurple;
    }
    case (4): {
      return colors.prestationGrey;
    }
    default: {
      return colors.prestationGrey;
    }
  }
};

/**
 * Replaces all occurrences of the placeholder "__ADHERENT_NAME__" in the given text with the specified adherent name.
 *
 * @param {string} text - The text containing the placeholder to be replaced.
 * @param {string} adherentName - The name of the adherent to replace the placeholder with.
 * @returns {string} - The text with all occurrences of the placeholder replaced by the adherent name.
 */
const replaceAdherentName = (text, adherentName = '') => text.replace(/__ADHERENT_NAME__/g, adherentName);

export default {
  getColorCode,
  replaceAdherentName,
};
