import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
// Constants
import strings from '../../constants/Strings';
import colors from '../../constants/Colors';
// Image
import leftArrow from '../../public/icons/arrow-big.svg';
// Component
import NewsCard from '../Atoms/NewsCard';
// Hooks
import useIsMobile from '../../hooks/useIsMobile';
import urlUtils from '../../utils/urlUtils';

const NewsBlock = ({ goToList, news, blockTitle }) => {
  const newsCarouselRef = useRef(null);
  const [isMobile,, screenWidth] = useIsMobile();

  const handleNewsScroll = useCallback((e) => {
    if (!isMobile) {
      e?.preventDefault();
      newsCarouselRef.current.scrollLeft += e?.deltaY;
    }
  }, [isMobile]);

  const getCardWidth = () => (screenWidth < 350 + 64 ? screenWidth - 64 : 350);

  useEffect(() => {
    if (newsCarouselRef?.current?.scrollLeft) { newsCarouselRef.current.scrollLeft = 350; }
  }, [newsCarouselRef]);

  useEffect(() => {
    if (newsCarouselRef?.current && !isMobile) {
      newsCarouselRef?.current?.addEventListener('wheel', handleNewsScroll);
    }

    return () => {
      if (newsCarouselRef?.current) {
        newsCarouselRef?.current?.removeEventListener('wheel', handleNewsScroll);
      }
    };
  }, [isMobile, newsCarouselRef]);

  return (
    <div className="grey-800-background p-4">
      <div className="d-flex f-row justify-between f-wrap align-baseline">
        <p className="h2 normal-weight white-text ml-3">{blockTitle}</p>
        {!isMobile && goToList ? (
          <button className="mb-6" type="button" onClick={goToList}>
            <div className="d-flex f-row align-center">
              <span className="primary-red-text bold mr-2">{strings.allAdvices}</span>
              <Image src={leftArrow} width={30} height={30} />
            </div>
          </button>
        ) : null}
      </div>
      <div
        ref={newsCarouselRef}
        className="d-flex f-row overflow-x-auto invisible-scroll-bar"
      >
        {
            news?.map(({
              id, title, illustrationFilePath,
            }) => (
              <div className="mh-3" key={id}>
                <NewsCard
                  link={urlUtils.getConseilUrl({ id, title })}
                  width={getCardWidth()}
                  content=""
                  title={title}
                  className="news-card-height"
                  image={illustrationFilePath}
                  color={colors.lightBlack}
                  textColor={colors.white}
                />
              </div>
            ))
        }
      </div>
      {isMobile && goToList ? (
        <button className="mv-5" type="button" onClick={goToList}>
          <div className="d-flex f-row align-center">
            <span className="primary-red-text bold mr-2">{strings.allAdvices}</span>
            <Image src={leftArrow} width={30} height={30} />
          </div>
        </button>
      ) : <div className="mb-6" /> }
    </div>
  );
};

NewsBlock.propTypes = {
  goToList: PropTypes.func,
  news: PropTypes.arrayOf(PropTypes.shape({})),
  blockTitle: PropTypes.string,
};

NewsBlock.defaultProps = {
  news: null,
  blockTitle: strings.advicesAndNews,
  goToList: null,
};

export default NewsBlock;
