import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { SlashContainer } from '@zolteam/axenergie-ui-library';
import { useHoverDirty } from 'react-use';
import Image from 'next/image';
import { useRouter } from 'next/router';
// Constants
import colors from '../../constants/Colors';
// Images
import rightArrow from '../../public/icons/arrow-big-black.svg';

const NewsCard = ({
  className,
  title,
  content,
  width,
  image,
  link,
  color,
  textColor,
}) => {
  const containerRef = createRef();
  const hovered = useHoverDirty(containerRef);
  const router = useRouter();
  return (
    <button
      ref={containerRef}
      type="button"
      onClick={() => {
        router.push(link);
      }}
      className={`${className} news-card-container`}
    >
      <div
        className="d-flex f-column align-start radius-5"
        style={{ width }}
      >
        {image
          ? (
            <div
              className="full-width bg-cover radius-5"
              style={{
                margin: 'auto',
                marginBottom: '-25px',
              }}
            >
              <Image
                width={350}
                height={200}
                src={`${process.env.NEXT_PUBLIC_IMAGE_PREFIX_URL}/public/${image}`}
                objectFit="cover"
                objectPosition="center"
                alt="news-image"
              />
            </div>
          )
          : null}
        <SlashContainer
          width="100%"
          backgroundColor={hovered ? colors.primaryRed : color}
          offset={10}
          position="top"
        >
          <div
            className={`d-flex f-column align-start radius-5 relative ${content ? 'pb-5' : ''}`}
            style={{ width }}
          >
            <p
              className="medium-weight ph-3 mt-3 mb-2 text-align-center full-width"
              style={{ color: hovered ? colors.white : textColor }}
            >
              {title}
            </p>
            {content ? (
              <div
                className="description-text ph-3 text-align-left wysiwyg-text ws-pre-wrap"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            ) : null}
            <div className="absolute d-flex align-end justify-end full-size p-2">
              {hovered
                ? <Image alt="arrow" src={rightArrow} width={30} height={30} />
                : null}
            </div>
          </div>
        </SlashContainer>
      </div>
    </button>
  );
};

NewsCard.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  width: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  color: PropTypes.string,
  textColor: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

NewsCard.defaultProps = {
  image: '',
  color: colors.white,
  textColor: colors.grey800,
  content: null,
};

export default NewsCard;
