import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { ModalTemplate } from '@zolteam/axenergie-ui-library';

const AdvertisementModal = ({
  closeModal,
  advertisement,
}) => (
  <ModalTemplate closeModal={closeModal}>
    <div className="m-4">
      <Image
        src={`${process.env.NEXT_PUBLIC_IMAGE_PREFIX_URL}/public/${advertisement}`}
        width={800}
        height={600}
        objectFit="contain"
        alt=""
      />
    </div>
  </ModalTemplate>
);

AdvertisementModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  advertisement: PropTypes.string,
};

AdvertisementModal.defaultProps = {
  advertisement: '',
};

export default AdvertisementModal;
