const formatPageTitle = (title) => {
  const suffix = ' | Axenergie'; // 12 characters
  const maxLenght = 70;
  return `${title?.substring(0, maxLenght - suffix.length)}${suffix}`;
};

const formatMetaDescription = (description) => {
  const maxLenght = 140;
  const first140Char = description?.replace(/<\/?[^>]+(>|$)/g, '')?.substring(0, maxLenght);
  const afterFirstPoint = first140Char?.split('.');
  const afterFirstExclamation = first140Char?.split('!');
  const afterFirstInterrogation = first140Char?.split('?');
  const afterFirstComa = first140Char?.split(',');
  if (afterFirstPoint?.length > 1) {
    return `${afterFirstPoint?.[0]}.`;
  }
  if (afterFirstExclamation?.length > 1) {
    return `${afterFirstExclamation?.[0]}!`;
  }
  if (afterFirstInterrogation?.length > 1) {
    return `${afterFirstInterrogation?.[0]}?`;
  }
  return afterFirstComa?.[0];
};

export default {
  formatPageTitle,
  formatMetaDescription,
};
