import React from 'react';
import PropTypes from 'prop-types';
import { ButtonComponent, SlashContainer } from '@zolteam/axenergie-ui-library';
import Image from 'next/image';
// Constants
import colors from '../../constants/Colors';
import strings from '../../constants/Strings';
// Images
import rightArrow from '../../public/icons/arrow-big-white.svg';
// Utils
import prestationUtils from '../../utils/prestationUtils';

const PrestationCard = ({
  className,
  title,
  subtitle,
  argumentation,
  price,
  width,
  link,
  color,
  subscribe,
  hasBorders,
  prestationType,
}) => {
  const sendDatalayerSubscribe = () => {
    if (!window) {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'contract',
      category: title,
    });
  };
  const sendDatalayerLearnMore = () => {
    if (!window) {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'find-out-more',
      category: title,
    });
  };
  const handleSubscribe = () => {
    sendDatalayerSubscribe();
    subscribe();
  };
  const handleLearnMore = () => {
    sendDatalayerLearnMore();
  };
  return (
    <div
      className={`${className}`}
    >
      <div
        className="d-flex f-column align-start radius-5 full-height white-background"
        style={{ width, border: hasBorders ? '1px solid #EDEEF1' : '' }}
      >
        <div
          className="d-flex full-width f-column align-center f-wrap top-radius"
          style={{ backgroundColor: prestationUtils.getColorCode(color), paddingBottom: 20, marginBottom: -20 }}
        >
          <div className="ph-3">
            <h3 className="h2 bold white-text text-align-center">{title}</h3>
            <p className="h5 medium-weight white-text text-align-center mt-0">{subtitle}</p>
          </div>
        </div>
        <SlashContainer
          width="100%"
          backgroundColor={colors.white}
          offset={10}
          position="top"
        >
          <div className="p-1" />
        </SlashContainer>
        <div
          className="d-flex f-column align-center justify-between radius-5 pb-5 full-height"
          style={{ width }}
        >
          <div
            className="p-4 text-align-center grey-400-text medium-text normal-weight wysiwyg-text"
            dangerouslySetInnerHTML={{ __html: argumentation }}
          />
          <div className="d-flex f-column align-center">
            <p
              className="medium-weight"
              style={{
                color: prestationUtils.getColorCode(color),
              }}
            >
              {price}
            </p>
            <ButtonComponent onClick={handleSubscribe}>
              <div className="d-flex justify-center align-center large-button mh-4 mv-1">
                <span className="mr-3 medium-weight">
                  {[2, 3].includes(prestationType) ? strings.beCalled : strings.subscribe}
                </span>
                <Image alt="" src={rightArrow} height={30} width={30} />
              </div>
            </ButtonComponent>
            <div className="mt-3">
              <a href={link} onClick={handleLearnMore}>
                <span className="primary-red-text bold">{strings.learnMore}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PrestationCard.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  argumentation: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  color: PropTypes.number.isRequired,
  prestationType: PropTypes.number.isRequired,
  subscribe: PropTypes.func.isRequired,
  hasBorders: PropTypes.bool,
};

PrestationCard.defaultProps = {
  hasBorders: true,
};

export default PrestationCard;
