import PropTypes from 'prop-types';
// Constants

const Rating = ({ rating }) => (
  <div className="d-flex">
    <svg id="stars" className="d-none" version="1.1">
      <symbol id="stars-full-star" viewBox="0 0 102 18">
        <path
          // eslint-disable-next-line max-len
          d="M9.5 14.25l-5.584 2.936 1.066-6.218L.465 6.564l6.243-.907L9.5 0l2.792 5.657 6.243.907-4.517 4.404 1.066 6.218"
        />
      </symbol>

      <symbol id="stars-half-star" viewBox="0 0 102 18">
        <path d="M9.5 14.25l-5.584 2.936 1.066-6.218L.465 6.564l6.243-.907L9.5 0l2.792" fill="#d01408" />
      </symbol>

      <symbol id="stars-all-star" viewBox="0 0 102 18">
        <use xlinkHref="#stars-full-star" />
        <use xlinkHref="#stars-full-star" transform="translate(21)" />
        <use xlinkHref="#stars-full-star" transform="translate(42)" />
        <use xlinkHref="#stars-full-star" transform="translate(63)" />
        <use xlinkHref="#stars-full-star" transform="translate(84)" />
      </symbol>

      <symbol id="stars-0-0-star" viewBox="0 0 102 18">
        <use xlinkHref="#stars-all-star" fill="#888E9B" />
      </symbol>

      <symbol id="stars-1-0-star" viewBox="0 0 102 18">
        <use xlinkHref="#stars-0-0-star" />
        <use xlinkHref="#stars-full-star" fill="#d01408" />
      </symbol>

      <symbol id="stars-2-0-star" viewBox="0 0 102 18">
        <use xlinkHref="#stars-1-0-star" />
        <use xlinkHref="#stars-full-star" fill="#d01408" transform="translate(21)" />
      </symbol>

      <symbol id="stars-3-0-star" viewBox="0 0 102 18">
        <use xlinkHref="#stars-2-0-star" />
        <use xlinkHref="#stars-full-star" fill="#d01408" transform="translate(42)" />
      </symbol>

      <symbol id="stars-4-0-star" viewBox="0 0 102 18">
        <use xlinkHref="#stars-3-0-star" />
        <use xlinkHref="#stars-full-star" fill="#d01408" transform="translate(63)" />
      </symbol>

      <symbol id="stars-5-0-star" viewBox="0 0 102 18">
        <use xlinkHref="#stars-all-star" fill="#d01408" />
      </symbol>
    </svg>

    <svg style={{ height: '16px', width: '102px' }}>
      <use xlinkHref={`#stars-${rating}-0-star`} />
    </svg>
  </div>
);

Rating.propTypes = {
  rating: PropTypes.oneOfType([0, 1, 2, 3, 4, 5]).isRequired,
};

export default Rating;
