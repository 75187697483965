/* eslint-disable import/prefer-default-export */
export const getBreadcrumbListSchema = (breadcrumbs) => JSON.stringify(
  {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs?.map((breadcrumb, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: breadcrumb?.name,
      item: `https://${process.env.NEXT_PUBLIC_DOMAIN}/${breadcrumb?.slug}`,
    })),
  }, null, 2,
);

export const getHVACBusinessSchema = (agency) => JSON.stringify(
  {
    '@context': 'http://schema.org',
    '@type': 'HVACBusiness',
    name: agency?.name,
    legalName: agency?.businessName,
    description: agency?.description,
    url: `https://${process.env.NEXT_PUBLIC_DOMAIN}/agences/${agency?.slug}`,
    email: agency?.emails[0]?.emailAddress,
    telephone: agency?.phones[0]?.phone,
    logo: `${process.env.NEXT_PUBLIC_IMAGE_PREFIX_URL}/public/${agency.logoFilePath}`,
    address: {
      '@type': 'PostalAddress',
      addressLocality: agency?.city,
      postalCode: agency?.postalCode,
      streetAddress: agency?.address1 || agency?.address2,
    },
    latitude: agency?.latitude,
    longitude: agency?.longitude,
    openingHours: agency?.openingHours,
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: agency?.rating?.value,
      reviewCount: agency?.rating?.reviewCount,
      author: agency?.rating?.author,
      worstRating: agency?.rating?.worstRating,
    },
    review: agency?.reviews?.map((review) => ({
      '@type': 'Review',
      author: review?.reviewer,
      datePublished: review?.date,
      reviewBody: review?.content,
      reviewRating: {
        '@type': 'Rating',
        ratingValue: review?.rating,
      },
    })),
  }, null, 2,
);

export const getAverageRating = (ratings) => {
  const total = ratings.reduce((acc, rating) => acc + rating.rating, 0);
  return Math.round((total / ratings.length) * 10) / 10;
};

export const getWorstRating = (ratings) => {
  const sortedRatings = ratings.filter((rating) => !!rating?.rating).sort((a, b) => a.rating - b.rating);
  return sortedRatings[0]?.rating;
};
