import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import Link from 'next/link';
// Constants
import strings from '../../constants/Strings';
// Images
import mailIcon from '../../public/icons/mail.svg';
import facebookIcon from '../../public/icons/facebook.svg';
import linkedinIcon from '../../public/icons/linkedIn.svg';
import instaIcon from '../../public/icons/instagram.svg';
import twitterIcon from '../../public/icons/twitter.svg';
// Hooks
import useIsMobile from '../../hooks/useIsMobile';

const AgencyInfoBlock = ({ agency }) => {
  const [isMobile,, screenWidth] = useIsMobile();

  const getSocialIcon = (socialLinkLabelId) => {
    switch (socialLinkLabelId) {
      case 1: {
        return facebookIcon;
      }
      case 2: {
        return linkedinIcon;
      }
      case 3: {
        return instaIcon;
      }
      case 4: {
        return twitterIcon;
      }
      default: {
        return mailIcon;
      }
    }
  };

  const getcontainerWidth = () => (screenWidth - 32 < 400 ? screenWidth - 32 : 380);

  return (
    <div className="white-background pt-2 mv-5" style={{ width: getcontainerWidth() }}>
      <div
        className={`d-flex justify-center align-center ${isMobile ? '' : 'ml-6'} mv-2`}
      >
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          alt={`${strings.heatingProIn} ${agency.city} - ${agency.name}`}
          src={`${process.env.NEXT_PUBLIC_IMAGE_PREFIX_URL}/public/${agency.logoFilePath}`}
          height={80}
        />
      </div>
      { agency.openingHours || agency.socialLinks?.length || agency.emails?.[0]?.emailAddress
        ? (
          <div className={`grey-100-background ${isMobile ? 'ph-1' : 'pl-6 ph-2'} pt-2 pb-4`}>
            {
                agency.openingHours
                  ? (
                    <>
                      <h2 className="h6 medium-weight grey-800-text ml-2 mb-0">{strings.openingHours}</h2>
                      <div
                        className="normal-weight medium-text grey-600-text ml-2 wysiwyg-text"
                        dangerouslySetInnerHTML={{ __html: agency.openingHours }}
                      />
                    </>
                  )
                  : null
            }
            {
                agency.socialLinks?.length || agency.emails?.[0]?.emailAddress
                  ? (
                    <div className="d-flex f-row align-center f-wrap">
                      <p className="h6 medium-weight grey-800-text ml-2 mr-3">
                        {strings.followUs}
                      </p>
                      <div className="d-flex f-row align-center mt-2">
                        {agency.emails?.[0]?.emailAddress ? (
                          <Link href={`mailto:${agency.emails[0].emailAddress}`}>
                            <a className="mh-2"><Image alt="email" src={mailIcon} width={20} /></a>
                          </Link>
                        ) : null}
                        {agency.socialLinks?.length
                          ? (
                            <div className="d-flex f-row align-center">
                              {
                                agency.socialLinks.map(({ socialLinkLabelId, link }) => (
                                  <Link
                                    href={link}
                                    key={socialLinkLabelId}
                                  >
                                    <a className="mh-2">
                                      <Image
                                        alt="email"
                                        src={getSocialIcon(socialLinkLabelId)}
                                        width={20}
                                      />
                                    </a>
                                  </Link>
                                ))
                            }
                            </div>
                          )
                          : null}
                      </div>
                    </div>
                  )
                  : null
             }
          </div>
        )
        : null}
    </div>
  );
};

AgencyInfoBlock.propTypes = {
  agency: PropTypes.shape({
    logoFilePath: PropTypes.string,
    openingHours: PropTypes.string,
    socialLinks: PropTypes.arrayOf(
      PropTypes.shape({ link: PropTypes.string, socialLinkLabelId: PropTypes.number }),
    ),
    emails: PropTypes.arrayOf(
      PropTypes.shape({ label: PropTypes.string, emailAddress: PropTypes.string }),
    ),
  }).isRequired,
};

export default AgencyInfoBlock;
