import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { format } from 'date-fns';
import { SlashContainer, ButtonComponent } from '@zolteam/axenergie-ui-library';

// Hooks
import useIsMobile from '../../hooks/useIsMobile';

// Constants
import strings from '../../constants/Strings';
import colors from '../../constants/Colors';

// Image
import arrow from '../../public/icons/arrow-big.svg';
import whiteArrow from '../../public/icons/arrow-big-white.svg';
import GoogleReviews from '../../public/Images/google-reviews.webp';

// Component
import Rating from '../Atoms/Rating';

const ReviewsBlock = ({ reviews, blockTitle, googleMyBusinessUrl }) => {
  const [isButtonHover, setIsButtonHover] = useState(false);
  const [isMobile] = useIsMobile();

  const openGmbUrl = () => {
    if (googleMyBusinessUrl) {
      window.open(googleMyBusinessUrl, '_blank').focus();
    }
  };

  return (
    <div className="grey-800-background pl-4 pr-4 pb-5 pt-0">
      <div className="d-flex f-row justify-between f-wrap">
        <div className="mb-4 pr-4" style={{ flex: isMobile ? '1 1 100%' : '.7' }}>
          <span className="d-flex align-items-center" style={{ flexDirection: isMobile ? 'column' : 'row' }}>
            <p
              className="h2 normal-weight white-text ml-3 mt-0 mr-5"
              style={{ marginBottom: isMobile ? '2rem' : '0' }}
            >
              {blockTitle}
            </p>
            <Image
              src={GoogleReviews}
              alt="google-reviews"
              layout="fixed"
              width={75}
              height={30}
            />
          </span>
          <div className="ml-3 mt-5">
            {reviews.map((review, index) => {
              let title = review.content.substr(0, 45);
              title = `${title.substr(0, Math.min(title.length, title.lastIndexOf(' ')))}...`;
              return (
                <React.Fragment key={review.id}>
                  <div>
                    <div className="d-flex align-center">
                      <Rating rating={review.rating} />
                      <div className="ml-3 h6 white-text medium-weight">{title}</div>
                    </div>
                    <div style={{ fontSize: '14px' }} className="h6 white-text grey-300-text mt-2">
                      par
                      {' '}
                      <b>{review.reviewer}</b>
                      {' '}
                      le
                      {' '}
                      {format(new Date(review.date.split(' ').join('T')), 'dd.MM.yyyy')}
                    </div>
                    <p style={{ color: '#888E9B' }}>
                      {review.content}
                    </p>
                  </div>
                  {index !== reviews.length - 1 && <div className="line grey-400-background opacity-01 mv-4" />}
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div style={{ flex: isMobile ? null : '.3', margin: isMobile ? '0 auto' : null }}>
          {googleMyBusinessUrl ? (
            <div
              className="white-background radius-5"
              style={{
                width: '350px', height: 'fit-content', margin: '0 auto',
              }}
            >
              <div
                className="full-width bg-cover radius-5"
                style={{
                  height: 200,
                  marginBottom: '-25px',
                  backgroundImage: 'url(/Images/rating.webp)',
                }}
              />
              <SlashContainer
                width="100%"
                backgroundColor={colors.white}
                offset={10}
                position="top"
              >
                <div
                  className="d-flex f-column align-start relative pb-3"
                  style={{ width: '350px' }}
                >
                  <p className="medium-weight ph-4 mt-4 mb-2 h5">
                    {strings.reviewRequest}
                  </p>
                  <p className="grey-600-text ph-4 h7">
                    {strings.reviewRequestSubtitle}
                  </p>
                  <div className="mv-3 ph-4 full-width d-flex justify-center">
                    <div
                      onMouseLeave={() => setIsButtonHover(false)}
                      onMouseEnter={() => setIsButtonHover(true)}
                    >
                      <ButtonComponent onClick={openGmbUrl} theme="outline">
                        <div
                          className="d-flex justify-center align-center large-button button-w-250"
                        >
                          <span>{strings.reviewRequestCta}</span>
                          <div className="mh-2">
                            {isButtonHover
                              ? <Image src={whiteArrow} width={30} alt="arrow" />
                              : <Image src={arrow} width={30} alt="arrow" />}
                          </div>
                        </div>
                      </ButtonComponent>
                    </div>

                    <div
                      className="absolute white-background"
                      style={{
                        bottom: 0,
                        left: 0,
                        transform: 'translateY(3px)',
                        borderRadius: '5px',
                        width: '100%',
                        height: '5px',
                        zIndex: 1,
                      }}
                    />

                  </div>
                </div>
              </SlashContainer>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

ReviewsBlock.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.shape({})),
  blockTitle: PropTypes.string,
  googleMyBusinessUrl: PropTypes.string,
};

ReviewsBlock.defaultProps = {
  reviews: null,
  blockTitle: strings.advicesAndNews,
  googleMyBusinessUrl: '',
};

export default ReviewsBlock;
